import { useAxios } from '@/composables/useAxios';

export const state = () => ({
  files: [],
  image: '',
  profile: null,
  nationality: [],
  loading: true,
  first_name: '',
  middle_name: '',
  last_name: '',
  userName: ' ',
  university: '',
  degree: '',
  major: '',
  GPA: '',
  phone_code: null,
  graduation_year: '',
  error: '',
  showError: '',
  token: null,
  referral_code: null,
});

export const actions = {
  async getProfile({ state }) {
    const token = useCookie('token');
    state.token = token.value;
    state.loading = true;
    const { api } = useAxios();
    await api
      .get('/auth/profile')
      .then(res => {
        state.loading = false;
        state.image = res.data.data.user.image;
        state.profile = res.data.data.user;
        state.first_name = res.data.data.user.first_name;
        state.middle_name = res.data.data.user.middle_name;
        state.last_name = res.data.data.user.last_name;
        state.userName = res.data.data.user.name;
        state.referral_code = res.data.data.user.referral_code;
        const phoneCode = state.profile.phone_code;
        const show = phoneCode?.flag ? phoneCode.flag : phoneCode.emoji;
        state.phone_code = {
          id: state.profile.phone_code.id,
          show: show,
          code: state.profile.phone_code.code,
          name: state.profile.phone_code.name,
        };
        state.nationality = {
          id: state.profile.nationality?.id,
          name: state.profile.nationality?.name,
        };
        const info = res?.data?.data?.user?.additional_info;
        state.university = info?.university;
        state.degree = info?.degree;
        state.major = info?.major;
        state.GPA = info?.GPA;
        state.graduation_year = info?.graduation_year;
      })
      .catch(async err => {
        console.log(err);
      });
  },
  async removeProfile({ state }) {
    const token = useCookie('token', { maxAge: -1 });
    await nextTick();
    token.value = null;
    state.token = null;
    await nextTick();
    state.referral_code = null;
    state.loading = false;
    state.image = '';
    state.profile = '';
    state.first_name = '';
    state.middle_name = '';
    state.last_name = '';
    state.userName = '';
    state.phone_code = [];
    state.nationality = [];
    state.university = '';
    state.degree = '';
    state.major = '';
    state.GPA = '';
    state.graduation_year = '';
  },

  getDocuments({ state }) {
    state.loading = true;
    const { api } = useAxios();
    api
      .get('/auth/uploaded-documents')
      .then(res => {
        state.files = res.data.data;
        state.loading = false;
      })
      .catch(() => {
        state.loading = false;
      });
  },
};
