import { useAxios } from '@/composables/useAxios';

export const state = () => ({
  loading: false,
  result: [],
  keyWord: '',
  query: '',
  order: '',
  per_page: 9,
});

export const actions = {
  getSearch({ state }, payload) {
    state.loading = true;
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }

    const { api } = useAxios();
    api
      .get(`/search?keyword=${state.keyWord}&per_page=${state.per_page}${state.query}&${state.order}`)
      .then(res => {
        state.data = res.data.data;
        state.loading = false;
        state.result = [
          ...state.data.map(item => ({
            id: item.id,
            type: item.type,
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            location: item.country,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
      })
      .then(() => {
        state.loading = false;
        // if (localStorage.getItem("APP_LANG") === "ar") {
        //   payload.router.push(`/ar/search`);
        // } else {
        payload.router.push(`/search`);
        // }
      })
      .catch(function () {
        state.loading = false;
      });
  },
};
