import { useAxios } from '@/composables/useAxios';
export const state = () => ({
  educationalLevel: [],
  Levels: [],
});

export const actions = {
  getEducationalLevel({ state }) {
    const { api } = useAxios();
    api.get('/auth/educational-levels').then(res => {
      state.educationalLevel = res.data.data;
    });
  },
  getLevels({ state }) {
    const { api } = useAxios();
    api.get('/levels').then(res => {
      state.Levels = res.data.data;
    });
  },
};
