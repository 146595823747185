import { useAxios } from '@/composables/useAxios';

export const state = () => ({
  institutes: [],
  institutesList: [],
  institutesListDestination: [],
  getInstitutesDestinationList: [],
  institutesListCity: [],
  loading: false,
  result: [],
  keyWord: '',
  query: '',
  order: '',
  per_page: 9,

  mark: '',
});

export const actions = {
  async getInstitutes({ state }) {
    const { api } = useAxios();
    await api.get(`/institutes`).then(res => {
      state.institutes = res.data.data;
      state.institutesList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `institute_ids[]${item.id}` };
      });
    });
  },

  async getInstitutesDestination({ state }) {
    let query = '';
    if (state.getInstitutesDestinationList.length) {
      query = `${state.getInstitutesDestinationList}`;
    }
    const { api } = useAxios();
    await api.get(`/institutes?per_page=400&sort=1&${query}`).then(res => {
      state.institutesListDestination = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `institute_ids[]${item.id}` };
      });
    });
  },

  async getInstitutesId({ state }, payload) {
    const { api } = useAxios();
    await api.get(`/english-courses/?institute_id=${payload}`).then(res => {
      state.institutesListID = res.data.data;
    });
  },

  async getInstitutesCity({ state }, payload) {
    if (!payload) {
      payload = '';
    }

    const { api } = useAxios();
    await api.get(`/institutes?per_page=400${payload}`).then(res => {
      state.institutesListCity = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&institute_ids[]=${item.id}` };
      });
    });
  },

  async getInstitutesSearch({ state }, payload) {
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    state.loading = true;
    const { api } = useAxios();
    await api
      .get(`/institutes?${state.keyWord}&per_page=${state.per_page}${state.query}&${state.order}`)
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.result = [
          ...state.data.map(item => ({
            id: item.id,
            type: item.type,
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            country: item.country.name,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
            logo: item.logo,
            max_fees: item.max_fees,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
};
