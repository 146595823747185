import { useAxios } from '@/composables/useAxios';

export const state = () => ({
  applicationList: [],
  loading: true,
  total: 0,
});

export const actions = {
  getApplication({ state }, page) {
    const pageNum = page ?? 1;
    if (pageNum == 1) {
      state.applicationList = [];
      state.total = 0;
    }
    state.loading = true;
    const { api } = useAxios();
    api.get(`/applications?per_page=15&page=${pageNum}`).then(res => {
      state.applicationList.push(...res.data.data);
      state.loading = false;
      if (res.data?.meta) {
        state.total = res.data.meta.total;
      }
    });
  },
};
