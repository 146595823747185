export const useAutoCompleteStore = defineStore('autoComplete', () => {
  const { $getApi } = useNuxtApp();

  const universities = ref([]);
  const universityCourses = ref([]);
  const institutes = ref([]);
  const englishCourses = ref([]);

  const autoCompleteById = ref([]);

  const getAutoComplete = () => {
    void getAutoCompleteUniversities();
    void getAutoCompleteInstitutes();
  };

  const getAutoCompleteUniversities = async () => {
    const res = await $getApi(`auto-complete-universities`);
    universities.value = res.data?.universities || [];
    universityCourses.value = res.data?.university_courses || [];
  };

  const getAutoCompleteInstitutes = async () => {
    const res = await $getApi(`auto-complete-institutes`);
    institutes.value = res.data?.institutes || [];
    englishCourses.value = res.data?.english_courses || [];
  };

  const getAutoCompleteById = async (category, id) => {
    const api = category === 'universities' ? 'auto-complete-universities' : 'auto-complete-institutes';
    const queryParamName = category === 'universities' ? 'university_id' : 'institute_id';
    const res = await $getApi(`${api}?${queryParamName}=${id}`);
    autoCompleteById.value =
      category === 'universities' ? res.data?.university_courses || [] : res.data?.english_courses || [];
  };

  const getAutoCompleteUniversitiesList = (isCoursesOnly = false) => {
    let arr = [];
    if (isCoursesOnly) {
      arr = [...universityCourses.value];
    } else {
      arr = [...universities.value, ...universityCourses.value];
    }
    return arr;
  };

  const getAutoCompleteInstitutesList = (isCoursesOnly = false) => {
    let arr = [];
    if (isCoursesOnly) {
      arr = [...englishCourses.value];
    } else {
      arr = [...institutes.value, ...englishCourses.value];
    }
    return arr;
  };

  return {
    autoCompleteById,
    getAutoComplete,
    getAutoCompleteById,
    getAutoCompleteUniversitiesList,
    getAutoCompleteInstitutesList,
  };
});
