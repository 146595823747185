import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReferralStore = defineStore('referral', () => {
  const DialogVisibility = ref(false);
  const DialogGetRewardVisibility = ref(false);
  const DataList = ref(null);
  const totalPagination = ref(null);
  const loadingData = ref(true);
  const PaginationFirst = ref(0);

  const getListData = async () => {
    const { api } = useAxios();
    await api.get(`/referral?per_page=5&page=1`).then(res => {
      loadingData.value = true;
      if (res.status === 200) {
        DataList.value = res.data.data;
        totalPagination.value = res.data.meta.total;
        PaginationFirst.value = 0;
      }
      loadingData.value = false;
    });
  };

  return {
    DialogVisibility,
    DialogGetRewardVisibility,
    DataList,
    totalPagination,
    loadingData,
    PaginationFirst,
    getListData,
  };
});
