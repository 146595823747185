export const state = () => ({
  monthEn: [
    { id: 1, name: 'January', query: `&intake_dates[]=${1}` },
    { id: 2, name: 'February', query: `&intake_dates[]=${2}` },
    { id: 3, name: 'March', query: `&intake_dates[]=${3}` },
    { id: 4, name: 'April', query: `&intake_dates[]=${4}` },
    { id: 5, name: 'May', query: `&intake_dates[]=${5}` },
    { id: 6, name: 'June', query: `&intake_dates[]=${6}` },
    { id: 7, name: 'July', query: `&intake_dates[]=${7}` },
    { id: 8, name: 'August', query: `&intake_dates[]=${8}` },
    { id: 9, name: 'September', query: `&intake_dates[]=${9}` },
    { id: 10, name: 'October', query: `&intake_dates[]=${10}` },
    { id: 11, name: 'November', query: `&intake_dates[]=${11}` },
    { id: 12, name: 'December', query: `&intake_dates[]=${12}` },
  ],
  monthAr: [
    { id: 1, name: 'يناير', query: `&intake_dates[]=${1}` },
    { id: 2, name: 'فبراير', query: `&intake_dates[]=${2}` },
    { id: 3, name: 'مارس', query: `&intake_dates[]=${3}` },
    { id: 4, name: 'أبريل', query: `&intake_dates[]=${4}` },
    { id: 5, name: 'مايو', query: `&intake_dates[]=${5}` },
    { id: 6, name: 'يونيو', query: `&intake_dates[]=${6}` },
    { id: 7, name: 'يوليو', query: `&intake_dates[]=${7}` },
    { id: 8, name: 'أغسطس', query: `&intake_dates[]=${8}` },
    { id: 9, name: 'سبتمبر', query: `&intake_dates[]=${9}` },
    { id: 10, name: 'أكتوبر', query: `&intake_dates[]=${10}` },
    { id: 11, name: 'نوفمبر', query: `&intake_dates[]=${11}` },
    { id: 12, name: 'ديسمبر', query: `&intake_dates[]=${12}` },
  ],
});
