import { useAxios } from '@/composables/useAxios';
export const state = () => ({
  levels: [],
  levelsList: [],
});

export const actions = {
  getLevels({ state }) {
    const { api } = useAxios();
    api.get(`/levels`).then(res => {
      state.levels = res.data.data;
      state.levelsList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&level_ids[]=${item.id}` };
      });
    });
  },
};
