import { useAxios } from '@/composables/useAxios';
export const state = () => ({
  count: '',
  notificationList: [],
  notificationListBell: [],
  per_page: 9,
});

export const actions = {
  getCountNumber({ state }) {
    const { api } = useAxios();
    api.get(`/notifications/count/unread`).then(res => {
      state.count = res.data.count;
    });
  },
  getNotificationsList({ state }) {
    const { api } = useAxios();
    api.get(`/notifications?&per_page=${state.per_page}`).then(res => {
      state.notificationList = res.data.data;
    });
  },
  getNotificationsListBell({ state }) {
    const { api } = useAxios();
    api.get(`/notifications?per_page=3`).then(res => {
      state.notificationListBell = res.data.data;
    });
  },
};
