export default defineNuxtPlugin(nuxtApp => {
  if (process.client && 'BroadcastChannel' in self) {
    const channel = new BroadcastChannel('fb-sw-messages');

    channel.onmessage = event => {
      if (event.data.type === 'NEW_MESSAGE') {
        nuxtApp.$store.dispatch('notification/getCountNumber');
        nuxtApp.$store.dispatch('notification/getNotificationsListBell');
      }
    };
  }
});
