import { getApps, initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig();

  try {
    const apps = getApps();
    const apiKey: string = (config?.public?.fcmAPIKey as string) ?? '';
    const app = !apps.length
      ? initializeApp({
          apiKey: apiKey,
          authDomain: 'student-center-f044a.firebaseapp.com',
          projectId: 'student-center-f044a',
          storageBucket: 'student-center-f044a.appspot.com',
          messagingSenderId: '211954307472',
          appId: '1:211954307472:web:9f003c4eda30241a4c2315',
          measurementId: 'G-FRQBSC7E9H',
        })
      : apps[0];
    const messaging = getMessaging(app);

    nuxtApp.vueApp.provide('messaging', messaging);
    nuxtApp.provide('messaging', messaging);

    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(err => {
          console.error('Service Worker registration failed:', err);
        });
    }
  } catch (error) {
    console.log(error);
  }
});
