export default defineNuxtPlugin(() => {
  const generalStatuses = [
    { id: 1, value: 'Draft', class: 'draft-status' },
    { id: 2, value: 'Submitted', class: 'submitted-status' },
    { id: 3, value: 'inProgress', class: 'progress-status' },
    { id: 4, value: 'Enrolled', class: 'submitted-status' },
    { id: 5, value: 'Rejected', class: 'rejected-status' },
    { id: 6, value: 'Closed', class: 'applied-status' },
  ];

  const zohoStatuses = [
    { id: 'Potential Application Review', textKey: 'applicationReview' },
    { id: 'Chasing University Offers', textKey: 'Offer' },
    { id: 'Payment/Sponsership', textKey: 'payment_sponsorship' },
    { id: 'Visa', textKey: 'visa' },
    { id: 'Enrollment', textKey: 'Enrolment' },
  ];

  return {
    provide: {
      globals: {
        generalStatuses: generalStatuses,
        zohoStatuses: zohoStatuses,
      },
    },
  };
});
