export const state = () => ({
  sort: false,
  searchList: false,
  profileMenu: false,
  docMenu: false,
  bodyOverFlow: false,
  showFlyButton: false,
  showNotification: false,
});

export const actions = {
  async bodyOverFlow({ state }) {
    state.bodyOverFlow = !state.bodyOverFlow;
  },
};
