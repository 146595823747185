import { useAxios } from '@/composables/useAxios';

export const state = () => ({
  loading: true,
  courseDetails: [],
  courseDetailsName: '',
  courseUniversityName: '',
  country: '',
  city: '',
  fund_price: '',
  level: '',
  logo: '',
  description: '',
  requirements: '',
  favourite: false,
  courseDetailsList: [],
  dates: [],
  duration: '',
  currency: '',
  image: '',
  map_link: '',
  is_season: 0,
  seasons: [],
});

export const actions = {
  getCourseDetails({ state }, payload) {
    state.loading = true;
    const { api } = useAxios();
    api.get(`/university-courses/${payload}`).then(res => {
      state.loading = false;
      state.courseDetails = res.data.data;
      state.courseDetailsName = res.data.data.name;
      state.courseUniversityName = res.data.data.university.name;
      state.country = res.data.data.country.name;
      state.city = res.data.data.city.name;
      state.fees = res.data.data.fees;
      state.fees_sale = res.data.data.fees_after_sale;
      state.has_sale = res.data.data.has_sale;
      state.application_fees = res.data.data.application_fees;
      state.university_application_fees = res.data.data.university_application_fees;
      state.level = res.data.data.level.name;
      state.description = res.data.data.description;
      state.requirements = res.data.data.requirements;
      state.favourite = res.data.data.favourite;
      state.is_season = res.data.data.is_season;
      state.dates = res.data.data.dates;
      state.seasons = res.data.data.seasons;
      state.duration = res.data.data.duration;
      state.image = res.data.data.image;
      state.currency = res.data.data.currency;
      state.logo = res.data.data.logo;
      state.map_link = res.data.data.map_link;
    });
  },
  restData({ state }) {
    state.courseDetails = '';
    state.courseDetailsName = '';
    state.courseUniversityName = '';
    state.country = '';
    state.city = '';
    state.fund_price = '';
    state.level = '';
    state.description = '';
    state.requirements = '';
    state.favourite = '';
    state.is_season = 0;
    state.dates = [];
    state.seasons = [];
    state.duration = '';
    state.image = '';
    state.currency = '';
    state.map_link = '';
  },
};
