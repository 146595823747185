export const state = () => ({
  documents: [],
});

export const actions = {
  toggleDocument({ state }, payload) {
    if (state.documents.includes(payload)) {
      state.documents = state.documents.filter(item => item !== payload);
    } else {
      state.documents.push(payload);
    }
  },
  cancelChoose({ state }) {
    state.documents = [];
  },
};
