import { useAxios } from '@/composables/useAxios';
export const state = () => ({
  degreeType: [],
  degreeTypeList: [],
});

export const actions = {
  getDegreeType({ state }) {
    const { api } = useAxios();
    api.get(`/degree-type`).then(res => {
      state.degreeType = res.data.data;
      state.degreeTypeList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&degree_type_ids[]=${item.id}` };
      });
    });
  },
};
